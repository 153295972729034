<template>
    <div class="component-box">
        <p class="mb-8 font-semibold">
            {{ $t('page.campaign.shop_settings') }}
        </p>

        <div class="flex flex-col space-y-6">
            <div>
                <p class="component-label">
                    {{ $t('common.ad_type') }}
                </p>
                <div class="flex space-x-4">
                    <div
                        v-for="(i, idx) in filter_ad_types"
                        :key="idx"
                        class="w-[280px] rounded-lg p-4 flex space-x-4 cursor-pointer border-[1px] box-border"
                        :class="[
                            shopping_ads_type === i.value
                                ? 'border-brand-atosa bg-white'
                                : 'border-transparent bg-bg hover:bg-border'
                        ]"
                        @click="handleChangeShoppingAdsType(i.value)"
                    >
                        <div>
                            <p class="font-semibold text-sm mb-1">
                                {{ i.title }}
                            </p>

                            <p class="component-text-desc min-h-[44px]">
                                {{ i.description }}
                            </p>
                        </div>
                    </div>
                </div>

                <el-alert
                    v-if="shopping_ads_type === 'PRODUCT_SHOPPING_ADS'"
                    class="mt-4"
                    :title="$t('page.campaign.desc_product_shopping_ads_2')"
                    type="warning"
                >
                </el-alert>
            </div>
            <el-radio-group
                v-model="radio_product_source"
                class="flex flex-col space-y-3"
                :disabled="type_form !== 'create'"
            >
                <el-radio v-if="this.shopping_ads_type !== 'LIVE'" :label="'STORE'">
                    <span class="component-label">
                        {{ $t('common.shop') }}
                    </span>
                    <div v-if="radio_product_source === 'STORE'">
                        <div id="input_adgroup_store_wrapper">
                            <el-select
                                id="input_adgroup_store"
                                v-model="selected_item_in_line.store_id"
                                :disabled="type_form !== 'create'"
                                size="small"
                                filterable
                                :placeholder="
                                    $t('input.placeholder.please_select')
                                "
                                class="w-[320px]"
                            >
                                <el-option-group :label="$t('common.shop')">
                                    <el-option
                                        v-for="item in shop_options"
                                        :key="item.store_id"
                                        :value="item.store_id"
                                        :label="item.store_name"
                                    >
                                        <div class="flex flex-col py-2">
                                            <span
                                                class="leading-3 text-xs font-semibold"
                                            >
                                                {{ item.store_id }}
                                            </span>
                                            <span class="component-text-desc">
                                                #{{ item.store_id }}
                                            </span>
                                        </div>
                                    </el-option>
                                </el-option-group>
                            </el-select>

                            <el-button
                                v-if="type_form === 'create'"
                                icon="el-icon-refresh"
                                size="small"
                                class="ml-2"
                                @click="fetchShop"
                            ></el-button>
                        </div>
                    </div>
                </el-radio>

                <el-radio
                    v-if="this.shopping_ads_type === 'VIDEO'"
                    :label="'SHOWCASE'"
                >
                    <span class="component-label"> Showcase </span>
                    <div v-if="radio_product_source === 'SHOWCASE'">
                        <div id="input_adgroup_store_wrapper">
                            <el-select
                                id="input_adgroup_store"
                                v-model="selected_item_in_line.showcase_id"
                                :disabled="type_form !== 'create'"
                                size="small"
                                filterable
                                :placeholder="
                                    $t('input.placeholder.please_select')
                                "
                                class="w-[320px]"
                            >
                                <el-option-group :label="$t('common.shop')">
                                    <el-option
                                        v-for="item in showcase_options"
                                        :key="item.identity_id"
                                        :value="item.identity_id"
                                        :label="item.identity_id"
                                    >
                                        <div class="flex flex-col py-2">
                                            <span
                                                class="leading-3 text-xs font-semibold"
                                            >
                                                {{ item.identity_id }}
                                            </span>
                                            <span class="component-text-desc">
                                                #{{ item.identity_type }}
                                            </span>
                                        </div>
                                    </el-option>
                                </el-option-group>
                            </el-select>

                            <el-button
                                v-if="type_form === 'create'"
                                icon="el-icon-refresh"
                                size="small"
                                class="ml-2"
                                @click="fetchShowcase"
                            ></el-button>
                        </div>
                    </div>
                </el-radio>
            </el-radio-group>
            <div
                v-if="
                    (shopping_ads_type !== 'PRODUCT_SHOPPING_ADS' &&
                        type_form === 'create') ||
                    shopping_ads_type === 'LIVE'
                "
                id="input_adgroup_identity_wrapper"
            >
                <div v-if="this.radio_product_source !== 'SHOWCASE'">
                    <p class="component-label">
                        {{ $t('common.identity') }}
                    </p>
                    <p class="component-text-desc mb-4">
                        {{ $t('page.campaign.desc_shop_settings_identity') }}
                    </p>

                    <div class="flex">
                        <el-select
                            id="input_adgroup_identity"
                            :value="selected_item_in_line.identity_id"
                            size="small"
                            :disabled="type_form !== 'create'"
                            filterable
                            :placeholder="
                                $t('input.placeholder.select_tiktok_account')
                            "
                            class="w-[320px]"
                            @change="handleChangeIdentity"
                        >
                            <el-option-group
                                :label="$t('common.tiktok_accounts')"
                            >
                                <el-option
                                    v-for="item in identity_options"
                                    :key="item.identity_id"
                                    :value="item.identity_id"
                                    :label="item.display_name"
                                >
                                    <div
                                        class="flex items-center py-1 space-x-2"
                                    >
                                        <el-avatar :size="32">
                                            <img
                                                v-if="item.profile_image_url"
                                                :src="item.profile_image_url"
                                            />
                                            <span v-else>
                                                {{ item.display_name }}
                                            </span>
                                        </el-avatar>
                                        <div class="flex flex-col">
                                            <span
                                                class="leading-5 text-xs font-semibold"
                                            >
                                                {{ item.display_name }}
                                            </span>
                                            <span
                                                v-if="
                                                    item.identity_authorized_bc_id
                                                "
                                                class="component-text-desc"
                                            >
                                                #{{
                                                    item.identity_authorized_bc_id
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </el-option>
                            </el-option-group>
                        </el-select>

                        <el-button
                            v-if="type_form === 'create'"
                            icon="el-icon-refresh"
                            size="small"
                            class="ml-2"
                            @click="fetchIdentity"
                        ></el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import selectedBusinessCenterMixin from '@/plugins/mixins/selected-business-center'
import {
    getIdentitiesFromAtosa,
    getIdentities
} from '@/services/atosa-tiktok-ads/identity'
import { getAssets } from '@/services/atosa-tiktok-ads/asset'
import { getStores, getShopsFromAtosa } from '@/services/atosa-tiktok-ads/store'
import { getIdentityShowcase } from '@/services/atosa-tiktok-ads/showcase'

export default {
    mixins: [selectedBusinessCenterMixin],

    props: [
        'selected_item',
        'tiktok_account_id',
        'belong_to_atosa',
        'advertiser_id',
        'type_form',
        'bc_id'
    ],

    data() {
        return {
            radio_product_source: 'STORE',
            fetch_shop_loading: false,
            fetch_identity_loading: false,
            identity_options: [],
            shop_options: [],
            showcase_options: [],
            store_options: [],
            shop_page_info: {
                page: 1,
                page_size: 10,
                total_number: 0,
                total_page: 1
            },
            identity_type: 'TT_USER',
            shopping_ads_type: 'VIDEO',
            ad_types: [
                {
                    title: this.$t('page.campaign.video_shopping'),
                    description: this.$t('page.campaign.desc_video_shopping'),
                    value: 'VIDEO'
                },
                {
                    title: this.$t('page.campaign.live_shopping'),
                    description: this.$t('page.campaign.desc_live_shopping'),
                    value: 'LIVE'
                },
                {
                    title: this.$t('page.campaign.product_shopping_ads'),
                    description: this.$t(
                        'page.campaign.desc_product_shopping_ads'
                    ),
                    value: 'PRODUCT_SHOPPING_ADS'
                }
            ]
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        },

        filter_ad_types() {
            if (this.type_form !== 'create') {
                return this.ad_types.filter(
                    (item) => item.value === this.shopping_ads_type
                )
            }

            return this.ad_types
        }
    },

    created() {
        // Gán giá trị của props vào data khi component được tạo
        this.radio_product_source = this.selected_item_in_line.product_source
            ? this.selected_item_in_line.product_source
            : 'STORE'
        this.selected_item_in_line.showcase_id =
            this.selected_item_in_line.identity_id
    },

    watch: {
        'selected_item.id'() {
            this.initData()
        },

        'selected_item.store_id'() {
            if (!this.selected_item.store_id) {
                return
            }

            const item = this.shop_options.find(
                (item) => item.store_id === this.selected_item_in_line.store_id
            )
            if (item) {
                this.selected_item_in_line.catalog_id = item.catalog_id
                this.selected_item_in_line.store_authorized_bc_id =
                    item.store_authorized_bc_id
                this.selected_item_in_line.extension.store_name =
                    item.store_name
            }
        },

        selected_item() {
            this.getInfoForState()

            let item = this.identity_options.find(
                (item) =>
                    item.identity_id === this.selected_item_in_line.identity_id
            )
            if (!item) {
                this.selected_item_in_line.identity_id = ''
            }

            item = this.shop_options.find(
                (item) => item.store_id === this.selected_item_in_line.store_id
            )
            if (!item) {
                this.selected_item_in_line.store_id = ''
                this.selected_item_in_line.catalog_id = ''
            }
        },
        radio_product_source() {
            this.selected_item_in_line.product_source =
                this.radio_product_source
        }
    },

    mounted() {
        this.initData()
    },

    methods: {
        async initData() {
            this.getInfoForState()

            await this.fetchShop()

            await this.fetchShowcase()

            this.fetchIdentity()
        },

        getInfoForState() {
            this.shopping_ads_type =
                this.selected_item_in_line?.shopping_ads_type
        },

        handleChangeIdentity(val) {
            this.selected_item_in_line.identity_id = val
            const item = this.identity_options.find(
                (i) => i.identity_id === val
            )

            if (item) {
                this.selected_item_in_line.extension.must_change_ad = true

                this.selected_item_in_line.identity_type = item.identity_type
                this.selected_item_in_line.identity_authorized_bc_id =
                    item.identity_authorized_bc_id

                this.selected_item_in_line.extension.identity.identity_id =
                    item.identity_id
                this.selected_item_in_line.extension.identity.identity_type =
                    item.identity_type
                this.selected_item_in_line.extension.identity.identity_authorized_bc_id =
                    item.identity_authorized_bc_id
                this.selected_item_in_line.extension.identity.display_name =
                    item.display_name
                this.selected_item_in_line.extension.identity.image_uri =
                    item.profile_image_url
            }
        },

        handleChangeShoppingAdsType(val) {
            this.shopping_ads_type = val

            this.identities = []

            this.selected_item_in_line.identity_id = ''

            this.radio_product_source = 'STORE'

            if (this.shopping_ads_type === 'VIDEO') {
                this.selected_item_in_line.extension.event_is_external = false
                this.selected_item_in_line.billing_event = 'CPC'

                this.selected_item_in_line.shopping_ads_type = 'VIDEO'
                this.selected_item_in_line.product_source =
                    this.radio_product_source
                this.selected_item_in_line.promotion_type = 'VIDEO_SHOPPING'
                this.selected_item_in_line.optimization_event = ''
                this.selected_item_in_line.comment_disabled = false
                this.selected_item_in_line.video_download_disabled = false
                this.identity_type = 'TT_USER'
                this.fetchIdentity()
            } else if (this.shopping_ads_type === 'LIVE') {
                this.selected_item_in_line.extension.event_is_external = false

                this.selected_item_in_line.shopping_ads_type = 'LIVE'
                this.selected_item_in_line.product_source = ''
                this.selected_item_in_line.promotion_type = 'LIVE_SHOPPING'
                this.selected_item_in_line.optimization_event = ''
                this.selected_item_in_line.comment_disabled = false
                this.selected_item_in_line.video_download_disabled = false
                this.identity_type = 'TT_USER'

                this.fetchIdentity()
            } else if (this.shopping_ads_type === 'PRODUCT_SHOPPING_ADS') {
                this.selected_item_in_line.extension.event_is_external = false

                this.selected_item_in_line.shopping_ads_type =
                    'PRODUCT_SHOPPING_ADS'
                this.selected_item_in_line.product_source = 'STORE'
                this.selected_item_in_line.promotion_type = 'PSA_PRODUCT'
                this.selected_item_in_line.optimization_event = ''
                this.selected_item_in_line.comment_disabled = true
                this.selected_item_in_line.video_download_disabled = true
                this.identity_type = ''
            }

            this.selected_item_in_line.optimization_goal = 'CLICK'
            this.selected_item_in_line.dayparting =
                this.p__default_dayparting_adgroup
            this.selected_item_in_line.extension.must_change_ad = true
        },

        async fetchIdentity() {
            if (this.shop_options.length === 0) {
                return
            }

            this.fetch_identity_loading = true

            try {
                if (this.belong_to_atosa) {
                    const response = await getIdentitiesFromAtosa(
                        this.tiktok_account_id,
                        {
                            advertiser_id: this.advertiser_id
                        }
                    )

                    if (response.code === 0) {
                        if (response?.data.length === 0) {
                            this.p__showNotify(
                                'warning',
                                'Bạn cần thêm danh tính để tạo quảng cáo mua sắm'
                            )

                            this.$router.push({
                                path: `${this.p__group_route.ads_manager}/${this.p__route.tiktok_identity}`
                            })
                        }

                        this.identity_options = response.data.filter(
                            (obj) => obj.identity_type !== 'CUSTOMIZED_USER'
                        )
                    }
                } else {
                    const response = await getIdentities(
                        this.tiktok_account_id,
                        {
                            advertiser_id: this.advertiser_id,
                            identity_type: 'BC_AUTH_TT'
                        }
                    )

                    if (response?.data.identity_list.length === 0) {
                        this.p__showNotify(
                            'warning',
                            'Bạn cần thêm danh tính để tạo quảng cáo mua sắm'
                        )

                        this.$router.push({
                            path: `${this.p__group_route.ads_manager}/${this.p__route.tiktok_identity}`
                        })
                    }

                    if (
                        response.code === 0 &&
                        response.data.identity_list.length > 0
                    ) {
                        this.identity_options = response.data.identity_list
                    }
                }
            } catch (error) {
                console.error(error)
            }

            if (this.type_form === 'create' && this.identity_options.length) {
                if (!this.selected_item_in_line.identity_id) {
                    this.handleChangeIdentity(
                        this.identity_options[0].identity_id
                    )
                }

                return
            }

            // if (!this.selected_item_in_line.identity_id) {
            //     this.selected_item_in_line.identity_id = ''
            //     this.p__event_bus.$emit(
            //         'eb_error_adgroup_no_find_identity',
            //         this.$t(
            //             'page.campaign.message_adgroup_identity_doesnt_connect'
            //         )
            //     )
            //     return
            // }

            // const item = this.identity_options.find(
            //     (item) =>
            //         item.identity_id === this.selected_item_in_line.identity_id
            // )

            // if (!item) {
            //     this.selected_item_in_line.identity_id = ''
            //     this.p__event_bus.$emit(
            //         'eb_error_adgroup_no_find_identity',
            //         this.$t(
            //             'page.campaign.message_adgroup_identity_doesnt_connect'
            //         )
            //     )
            // }

            this.fetch_identity_loading = false
        },

        async fetchShop() {
            if (!this.advertiser_id) {
                return
            }

            try {
                const response = await getShopsFromAtosa(
                    this.tiktok_account_id,
                    {
                        advertiser_id: this.advertiser_id,
                        belong_to_atosa: this.belong_to_atosa,
                        bc_id: this.bc_id
                    }
                )

                if (response?.data.length === 0) {
                    this.p__showNotify(
                        'warning',
                        'Bạn cần thêm Tiktok shop để tạo quảng cáo mua sắm'
                    )

                    this.$router.push({
                        path: `${this.p__group_route.ads_manager}/${this.p__route.tiktok_shop}`
                    })

                    return
                }

                this.shop_options = response.data

                if (this.type_form === 'create' && this.shop_options.length) {
                    if (!this.selected_item_in_line.store_id) {
                        this.selected_item_in_line.store_authorized_bc_id =
                            this.shop_options[0].store_authorized_bc_id
                        this.selected_item_in_line.store_id =
                            this.shop_options[0].store_id
                        this.selected_item_in_line.catalog_id =
                            this.shop_options[0].catalog_id
                    }

                    return
                }

                if (this.selected_item_in_line.product_source !== 'SHOWCASE') {
                    if (!this.selected_item_in_line.store_id) {
                        this.selected_item_in_line.store_authorized_bc_id = ''
                        this.selected_item_in_line.store_id = ''
                        this.selected_item_in_line.catalog_id = ''

                        this.p__event_bus.$emit(
                            'eb_error_adgroup_no_find_shop',
                            this.$t(
                                'page.campaign.message_adgroup_store_doesnt_connect'
                            )
                        )
                        return
                    }
                }

                const item = this.shop_options.find(
                    (item) =>
                        item.store_id === this.selected_item_in_line.store_id
                )

                if (this.selected_item_in_line.product_source !== 'SHOWCASE') {
                    if (!item) {
                        this.selected_item_in_line.store_authorized_bc_id = ''
                        this.selected_item_in_line.store_id = ''
                        this.selected_item_in_line.catalog_id = ''
                        this.p__event_bus.$emit(
                            'eb_error_adgroup_no_find_shop',
                            this.$t(
                                'page.campaign.message_adgroup_store_doesnt_connect'
                            )
                        )
                    }
                }
            } catch (error) {
                console.error(error)
            }
        },

        async fetchShowcase() {
            if (!this.advertiser_id) {
                return
            }

            try {
                const response = await getIdentityShowcase(
                    this.tiktok_account_id,
                    {
                        advertiser_id: this.advertiser_id
                    }
                )

                if (response?.data.length === 0) {
                    this.p__showNotify('warning', 'Chưa có showcase nào')
                }

                this.showcase_options = response.data.identity_list

                if (
                    this.type_form === 'create' &&
                    this.showcase_options.length
                ) {
                    if (!this.selected_item_in_line.showcase_id) {
                        this.selected_item_in_line.showcase_id =
                            this.showcase_options[0].identity_id

                        this.selected_item_in_line.extension.must_change_ad = true

                        this.selected_item_in_line.identity_type =
                            this.showcase_options[0].identity_type
                        this.selected_item_in_line.identity_authorized_bc_id =
                            this.showcase_options[0].identity_authorized_bc_id
                    }

                    return
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
