import Layout from '@/layout'
import { p__route, p__group_route } from '@/plugins/prototypes'
import i18n from '@/plugins/language'

export const generalRoutes = {
    path: p__group_route.general,
    component: Layout,
    name: i18n.t('common.system'),
    isGroup: true,
    children: [
        {
            path: p__route.whitelist,
            component: () => import('@/views/whitelist'),
            meta: {
                title: `Whitelist`,
                icon: 'el-icon-set-up'
            }
        },
        {
            path: p__route.statistical,
            component: () => import('@/views/statistical'),
            meta: {
                title: `${i18n.t('common.integration_google')}`,
                icon: 'el-icon-set-up'
            }
        },
        {
            path: p__route.permission,
            component: () => import('@/views/permission'),
            meta: {
                title: `${i18n.t('common.second_account')}`,
                icon: 'el-icon-service'
            }
        },
        {
            path: p__route.credit_card,
            component: () => import('@/views/credit-card'),
            meta: {
                title: `${i18n.t('common.billing')}`,
                icon: 'el-icon-bank-card'
            }
        },
        {
            path: p__route.affiliate,
            component: () => import('@/views/affiliate'),
            meta: {
                title: `${i18n.t('common.affiliate')}`,
                icon: 'el-icon-medal-1'
            }
        },
        {
            path: p__route.package,
            component: () => import('@/views/package'),
            meta: {
                title: `${i18n.t('common.package')}`,
                icon: 'el-icon-postcard'
            }
        },
        {
            path: '/doi-soat',
            component: () => import('@/views/history-payment'),
            hidden: true
        },
        {
            path: p__route.admin_payment,
            component: () => import('@/views/admin/payment'),
            meta: {
                title: `${i18n.t('page.admin.recharge_history')}`,
                icon: 'el-icon-tickets'
            },
            hidden: true,
            is_admin: true
        }
    ]
}
